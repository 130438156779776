.home-slider {
  position: relative;
  //z-index: -1;
  width: 100%;

  @include media-breakpoint-up(lg) {
    transform: translateY(-1.5rem);
  }

  @include media-breakpoint-only(lg) {
    display: flex;
    height: 30rem;
  }

  @include media-breakpoint-only(xl) {
    display: flex;
    height: 37rem;
  }

  @include media-breakpoint-only(xxl) {
    display: flex;
    height: 35rem;
  }

  .home-slider-content {
    width: 100%;
    min-height: 20rem;

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;
    }
  }

  .home-slider-media {
    width: 100%;
    height: 100vw;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .home-slider-nav {
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 50px;
      right: 0;
    }

    .home-slider-nav-prev,
    .home-slider-nav-next {
      cursor: pointer;
    }
  }

  .home-slider-bg {
    position: absolute;
    top: -90%;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  //

  .home-slider-video {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .home-slider-obraz {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.home-program-loop-4 {
  @include media-breakpoint-only(xxl) {
    .tease-image {
      height: 460px;
      overflow: hidden;
    }
  }

  @include media-breakpoint-only(xl) {
    .tease-image {
      height: 360px;
      overflow: hidden;
    }
  }

  @include media-breakpoint-only(lg) {
    .tease-image {
      height: 300px;
      overflow: hidden;
    }
  }
}

.title {
  border-bottom: 5px solid #FFF700;
  margin-bottom: 2.5rem;
  padding-bottom: 1rem;
}

.navbar-hamburger {
  display: block;
  width: 50px;
  height: 3px;
  background: #000;
  margin: 12px 0;
}

.wp-block-image img {
  height: auto;
}

.wp-block-table {
  overflow-x: auto;

  td {
    padding: 1rem;
  }

  &.table-buttons {
    tr td:last-child {
      a {
        background: #000;
        color: #fff;
        padding: 0.7rem 2rem;
        margin: 1.2rem 0;
        white-space: nowrap;
      }
    }
  }
}

.card-guest {
  .card-img-overlay {
    opacity: 0;
    transition: .2s all;
  }

  &:hover {
    .card-img-overlay {
      opacity: 1;
      background: rgba(#000, 0.7);
    }
  }
}

.template-submenu {
  a {
    display: inline-block;
    font-size: $h5-font-size;
    font-family: $headings-font-family;
    margin-bottom: 1rem;
  }

  .current-menu-item {
    a {
      font-weight: 600;
    }
  }
}

[class^="btn btn-outline"] {
  border-width: 2px !important;
}

.blocks-gallery-item {
  .blocks-gallery-item__caption{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(#000, 0.75) !important;
    color: #fff !important;
    opacity: 0;
    transition: opacity 0.2s;

    a {
      display: inline-block !important;
      color: #fff !important;
      height: auto !important;
    }

    &:hover {
      opacity: 1;
    }
  }


}

html { font-size: 1.1rem; }

$enable-responsive-font-sizes: true;

$primary: #92141F;
$secondary: #000;
$dark: #000;

$headings-font-family: 'Poppins';
$headings-font-weight: 600;

$body-color: #000;
$link-color: $body-color;

$font-size-base: 1rem;
$h5-font-size: $font-size-base * 1.1;

$navbar-padding-x: 0;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$grid-gutter-width: 20px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);

$pagination-border-width: 0;
$pagination-active-bg: #000;

$input-btn-focus-color: rgba(#000, .1);

$btn-padding-y-lg: .7rem;
$btn-padding-x-lg: 3rem;

$input-border-color: #000;
$input-border-width: 2px;

.navbar {

  .navbar-nav {
    background: #000;
  }

  .nav-link {
    display: inline-block;
    background: #000;
    color: #fff;
    font-size: $h5-font-size;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;

    &:hover {
      background: #222;
    }

    @include media-breakpoint-down(lg) {
      padding: 1rem 1.2rem !important;
    }

    @include media-breakpoint-only(lg) {
      padding: 1rem 0.8rem !important;
    }

    @include media-breakpoint-only(xl) {
      padding: 1rem 1.4rem !important;
    }

    @include media-breakpoint-up(xxl) {
      padding: 1rem 2.6rem !important;
    }
  }

  .dropdown-menu {
    background: #000;
    a {
      color: #fff;
      font-weight: 300 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .nav-item.dropdown.show {
      .nav-link {
        background: #fff;
        color: #000;
      }
    }

    .dropdown-menu {
      background: #fff;
      a {
        color: #000;
      }
    }
  }

  .current-menu-parent .nav-link,
  .current-menu-item .nav-link {
    background: #fff;
    color: #000;
  }
}

.search-bar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  height: 71px;
}
